.centerbox {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: rgb(255 255 255 / 79%);
  position: fixed;
  z-index: 999999;
  top: 0;
  backdrop-filter: blur(5px);
}
  .centerbox img{
    max-width:150px; 
  }
  .rotate {
    width: 55px;
    animation: rotation 2s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .loader {
    width: 48px;
    height: 48px;
    margin: auto;
    position: relative;
  }
  
  .loader:before {
    content: '';
    width: 48px;
    height: 5px;
    background: #c4c4c4;
    position: absolute;
    top: 60px;
    left: 0;
    border-radius: 50%;
    animation: shadow324 0.5s linear infinite;
    filter: blur(0px);
}
  
  .loader:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #044080;
    background: linear-gradient(#802f92, #528ac7);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 40px;
    animation: jump7456 0.5s linear infinite;
  }
  
  @keyframes jump7456 {
    15% {
      border-bottom-right-radius: px;
    }
  
    25% {
      transform: translateY(15px) rotate(22.5deg);
    }
  
    50% {
      transform: translateY(17px) scale(1, .9) rotate(45deg);
      border-bottom-right-radius: 40px;
    }
  
    75% {
      transform: translateY(4px) rotate(50.5deg);
    }
  
    100% {
      transform: translateY(4) rotate(90deg);
    }
  }
  
  @keyframes shadow324 {
  
    0%,
      100% {
      transform: scale(2, 2);
    }
  
    50% {
      transform: scale(2, );
    }
  }
